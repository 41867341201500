import React from 'react';
import { Link } from 'react-router-dom';

export default function AboutUs() {
  return (
      <div className="aboutBlock container">
        <div className="aboutHeader Jomolhari font-bold text-center">
          Crafting Excellence, Sustaining Tomorrow
          <br/><span className='aboutHeaderSecond Jomolhari-Bold'>The Ko Mhendo difference!</span>
        </div>
        <div className="smAboutHeader Jomolhari font-bold text-center">
          Crafting Excellence, Sustaining Tomorrow
          <br />
          The Ko_Mhendo difference!
        </div>
      </div>
  );
}

import React, { useState, useEffect } from 'react';
import jsonData from '../JsonFiles/blogs.json';
import { useLocation } from 'react-router-dom';
import '../Styles/BlogPage.css';
import { scrollToTop } from '../miscellaneousFunc';
import ProductsCarouselSquare from '../Components/Common/ProductsCarouselSquare';

export default function BlogPage() {
  const [blogData, setBlogData] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const location = useLocation();

  // Fetch blog data based on blogId from JSON
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const blogId = params.get('blogId');
    if (blogId) {
      const blog = jsonData.find((blog) => blog.blogId === blogId);
      if (blog) {
        setBlogData(blog);
      } else {
        setNotFound(true);
      }
    } else {
      setNotFound(true);
    }
  }, [location.search]);

  if (notFound) {
    return (
      <div className="container Jomolhari d-flex items-center justify-center font-bold">
        Blog not found
      </div>
    );
  }

  return (
    <div className="blogPageContainer container">
      {blogData && (
        <>
          <div
            className={`${
              blogData.titleAndSubtitleAlign === 'left' &&
              window.innerWidth > 767
                ? 'blogPageTitleImageLeft'
                : blogData.titleAndSubtitleAlign === 'right' &&
                  window.innerWidth > 767
                ? 'blogPageTitleImageRight'
                : 'blogPageTitleImageCenter'
            }`}
          >
            <div
              className={`${
                blogData.titleAndSubtitleAlign === 'left' &&
                window.innerWidth > 767
                  ? 'blogPageTitleImgTagSmallLeft'
                  : blogData.titleAndSubtitleAlign === 'right' &&
                    window.innerWidth > 767
                  ? 'blogPageTitleImgTagSmallRight'
                  : 'blogPageTitleImgTagSmallCenter'
              }`}
            >
              {blogData.blogTitle}
            </div>
            <div
              className={`Jomolhari ${
                blogData.titleAndSubtitleAlign === 'left' &&
                window.innerWidth > 767
                  ? 'blogPageTitleImgTagLeft'
                  : blogData.titleAndSubtitleAlign === 'right' &&
                    window.innerWidth > 767
                  ? 'blogPageTitleImgTagRight'
                  : 'blogPageTitleImgTagCenter'
              }`}
            >
              {blogData.blogSubTitle}
            </div>
            {blogData.blogTitleImageType !== '' && (
              <div className={blogData.blogTitleImageType}></div>
            )}
            <img
              src={blogData.blogTitleImageLink}
              alt={blogData.blogTitle}
              className="blogTitleImage"
            />
            <img
              src={blogData.smBlogTitleImageLink}
              alt={blogData.blogTitle}
              className="smBlogTitleImage"
            />
          </div>
          {blogData.blogPageTopSlogan && (
            <div className="blogPageTopSlogan Jomolhari">
              <div className="sloganInternalSpan" dangerouslySetInnerHTML={{__html: blogData.blogPageTopSlogan}}>
                {/* {blogData.blogPageTopSlogan} */}
              </div>
            </div>
          )}
          <div className="blogPageTopSection Inter">
            <div className="paragraphWrapper">
              {blogData.blogPageTopSection.map((section) => (
                <div
                  key={section.pgId}
                  className="blogPageParagraph"
                  dangerouslySetInnerHTML={{ __html: section.pgDesc }}
                ></div>
              ))}
            </div>
          </div>
          {/* <div className="blogPageMidContentImage"> */}
          {/* <img
              src={blogData.blogPageMidContentImage}
              alt={blogData.blogTitle}
              className="blogPageMidContentImageLg"
            />
            <img
              src={blogData.smBlogPageMidContentImage}
              alt={blogData.blogTitle}
              className="smBlogPageMidContentImage"
            /> */}
          {/* 
          </div> */}
          <ProductsCarouselSquare
            blogId={blogData.blogId}
          />
          <div className="blogPageMidContentSection Inter">
            <div className="paragraphWrapper">
              {blogData.blogPageMidSection.map((section) => (
                <div
                  key={section.pgId}
                  className="blogPageParagraph"
                  dangerouslySetInnerHTML={{ __html: section.pgDesc }}
                ></div>
              ))}
            </div>
          </div>
          {blogData.blogPageMidPageSlogan && (
            <div className="blogPageMidPageSlogan Jomolhari">
              <div className="sloganInternalSpan">
                {blogData.blogPageMidPageSlogan}
              </div>
            </div>
          )}
          {blogData.blogPageBottomSection && (
            <div className="blogPageBottomContentSection Inter">
              <div className="paragraphWrapper">
                {blogData.blogPageBottomSection.map((section) => (
                  <div key={section.pgId} className="blogPageParagraph">
                    {section.pgDesc}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

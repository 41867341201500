import React, { useState, useEffect } from "react";
import imageData from "../../JsonFiles/HeroSectionImages.json";
import { importImage } from "../../miscellaneousFunc";
import { useNavigate } from "react-router-dom";


const HeroSection = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState(imageData.images);
  const [imageDimensions, setImageDimensions] = useState([]);
  const [key, setKey] = useState(0); // Key for remounting
  const [mountDetailsWrapper, setMountDetailsWrapper] = useState(false);

  useEffect(() => {
    const images = document.querySelectorAll(".image-gallery img");
    const dimensions = Array.from(images).map((img) => ({
      width: img.naturalWidth,
      height: img.naturalHeight,
    }));
    setImageDimensions(dimensions);
  }, [images]);

  const handleImageLoad = (index) => {
    // Check if all image dimensions are non-zero
    const allDimensionsNonZero = imageDimensions.every(
      (dim) => dim.width !== 0 || dim.height !== 0
    );
    if (allDimensionsNonZero) {
      setMountDetailsWrapper(true);
      setKey((prevKey) => prevKey + 1); // Update key to force remount
    }
  };

  return (
    <div
      className="container image-gallery"
      style={{
        backgroundImage: `
          url('../Images/hero.jpg')
        `,
        backgroundSize: 'cover',  
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* {images.map((image, index) => (
        <div className="image-wrapper" key={image.id}>
          {
            <div className="detailsWrapper">
              {image.imgTagOne !== "" && (
                <div className="imgTagOne Inter">{image.imgTagOne}</div>
              )}
              {image.imgTagTwo !== "" && (
                <div className="imgTagTwo Inter">{image.imgTagTwo}</div>
              )}
              {image.buttonText !== "" && (
                <button
                  className={`${image.buttonState} Inter-Medium heroSectionButton`}
                  onClick={() =>
                    navigate(
                      `/collection/${image.imgTagTwo
                        .toLowerCase()
                        .replace(/[\s/]/g, "-")}`
                    )
                  }
                >
                  {image.buttonText}
                </button>
              )}
            </div>
          }
          {image.className !== "" && (
            <div className={`${image.className} imageHeightSetter`}></div>
          )}
          <img className="imageHeightSetter" src={importImage(image.src)} alt={`Image ${image.id}`} />
        </div>
      ))} */}
    </div>
  );
};

export default HeroSection;
